// exports.server = 'http://localhost:8080/';



// const isDevelopment = process.env.NODE_ENV === "development";
const api = process.env.REACT_APP_API_URL;

console.log("API: ", api);

// get current protocol
const protocol = window.location.protocol;
console.log("protocol: ", protocol);

// add protocol to api

// console.log(process.env.NODE_ENV);

exports.server =  protocol + '//' + api;

